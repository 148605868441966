/**
 * Gets the first name, technically gets all words leading up to the last
 * Example: "Blake Robertson" --> "Blake"
 * Example: "Blake Andrew Robertson" --> "Blake Andrew"
 * Example: "Blake" --> "Blake"
 * @param name
 * @returns {*}
 */
const getFirstName = (name) => {
  if (!name) {
    return '';
  }
  const arr = name.split(' ');
  if( arr && arr.length === 1 ) {
      return arr[0];
  }
  return arr.slice(0, -1).join(' ');
}

/**
 * Gets the last name (e.g. the last word in the supplied string)
 * Example: "Blake Robertson" --> "Robertson"
 * Example: "Blake Andrew Robertson" --> "Robertson"
 * Example: "Blake" --> ""
 * @param name
 * @returns {string}
 */
const getLastName = (name) => {
  if (!name) {
    return '';
  }
  const arr = name.split(' ');
  if(arr && arr.length === 1) {
      return "";
  }
  return arr.slice(-1).join(' ');
}

/**
 * Gets title case (e.g. the first letter is capitalized)
 * Example: "blake" --> "Blake"
 * Example: "BLAKE" --> "Blake"
 * @param str
 * @returns {string}
 */
const getTitleCase = (str) => {
  str = str.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
  }
  return str.join(' ');
}

/**
 * Adds a classname to Body
 * @param str
 * @returns void
 */
const addBodyClass = (cls) => {
  if (!document.body.classList.contains(cls)) {
    document.body.classList.add(cls);
  }
}

/**
 * Remove body class
 * @param str
 * @returns void
 */
const removeBodyClass = (cls) => {
  document.body.classList.remove(cls);
}

//display only last 4 digits phone nr
const hidePhoneNr = (phone) => {
  const regex = /\d(?=\d{4})/gm;
  const subst = `*`;
  if (phone) {
    phone = phone.replace(/\D+/g, "");
    const result = phone.replace(regex, subst);
    return result;
  }
}

const formatCurrency = (value) => {
  let formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  if (value % 1 === 0) {
    formatted = formatted.split('.')[0]; // Remove .00 at the end
  }
  return formatted;
};

const abbreviateState = (input) => {
  const states = {
      'Alabama': 'AL',
      'Alaska': 'AK',
      'Arizona': 'AZ',
      'Arkansas': 'AR',
      'California': 'CA',
      'Colorado': 'CO',
      'Connecticut': 'CT',
      'Delaware': 'DE',
      'Florida': 'FL',
      'Georgia': 'GA',
      'Hawaii': 'HI',
      'Idaho': 'ID',
      'Illinois': 'IL',
      'Indiana': 'IN',
      'Iowa': 'IA',
      'Kansas': 'KS',
      'Kentucky': 'KY',
      'Louisiana': 'LA',
      'Maine': 'ME',
      'Maryland': 'MD',
      'Massachusetts': 'MA',
      'Michigan': 'MI',
      'Minnesota': 'MN',
      'Mississippi': 'MS',
      'Missouri': 'MO',
      'Montana': 'MT',
      'Nebraska': 'NE',
      'Nevada': 'NV',
      'New Hampshire': 'NH',
      'New Jersey': 'NJ',
      'New Mexico': 'NM',
      'New York': 'NY',
      'North Carolina': 'NC',
      'North Dakota': 'ND',
      'Ohio': 'OH',
      'Oklahoma': 'OK',
      'Oregon': 'OR',
      'Pennsylvania': 'PA',
      'Rhode Island': 'RI',
      'South Carolina': 'SC',
      'South Dakota': 'SD',
      'Tennessee': 'TN',
      'Texas': 'TX',
      'Utah': 'UT',
      'Vermont': 'VT',
      'Virginia': 'VA',
      'Washington': 'WA',
      'West Virginia': 'WV',
      'Wisconsin': 'WI',
      'Wyoming': 'WY'
  };

  return states[input] || input;
}


export {
  getFirstName,
  getLastName,
  getTitleCase,
  addBodyClass,
  removeBodyClass,
  hidePhoneNr,
  formatCurrency,
  abbreviateState
};
